import React, { Component, createRef } from 'react';
import moment from "moment";
import { connect } from "react-redux";
import { A } from 'hookrouter';
import {
    updateInviteStatus,
    getUserInviteDetails,
    getGameMembers,
    endGame,
} from "../../Redux/api";
import Popup from '../common/Popup';
import axios from 'axios';
import { getRoomListAction } from '../../Redux/chat';
import ShareIcon from '../../images/share-icon.png';
import { isEventCompleteOrEnded, navigateX } from '../../helpers';
import MoreIcon from '../../images/icons/more-icon.svg';
import RightArrow from '../../images/arrow.png';
import TwinkleStar from '../../images/stars.png';
import ShareIconDots from '../../images/share.png';
import MembersIcon from '../../images/people.png';
import { eventStatusObj } from '../Constants/constants';

class EventCard extends Component {
    static propTypes = {};

    constructor(args) {
        super(args);
        this.actionMenuRef = createRef();
        this.memberListRef = createRef();
        this.handleOutsideClick = this.handleOutsideClick.bind(this);

        this.state = {
            days: '0',
            hours: '00',
            minutes: '00',
            seconds: '00',
            disableStart: true,
            enableButton: false,
            errorMsg: '',
            copyText: '',
            members: [],
            showEndGame: false,
            showMembersPopUp: false,
            showDropdown: false,
        };
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleOutsideClick);

        const { data } = this.props;
        if (!data) {
            return;
        }

        const startTime = parseInt(data.event.startTime);

        this.interval = setInterval(() => {
            const currentTime = new Date().getTime();
            var diff = moment.duration(moment(startTime).diff(moment(currentTime)));

            const { days, hours, minutes, seconds } = diff._data;

            let endTime = new Date(startTime)
            const duration = parseInt(data.event.duration);
            endTime.setHours(endTime.getHours(), endTime.getMinutes() + duration, 0, 0);


            let now = new Date();
            if (endTime < now) {
                this.setState({ showEndGame: true })
            }

            if (startTime < currentTime) {
                this.setState({
                    disableStart: false,
                    enableButton: true,
                    days: '0',
                    hours: '00',
                    minutes: '00',
                    seconds: '00',
                });
                clearInterval(this.interval);
            } else {
                this.setState({
                    days,
                    enableButton: true,
                    hours: hours < 10 ? '0' + hours.toString() : hours,
                    minutes: minutes < 10 ? '0' + minutes.toString() : minutes,
                    seconds: seconds < 10 ? '0' + seconds.toString() : seconds
                });
            }
        }, 1000);

        getGameMembers(data.event.id).then(resp => {
            if (resp.data.success) {
                this.setState({ members: resp.data.data })
            }
        });

    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleOutsideClick);
    }

    handleOutsideClick = (event) => {
        if (!this.state.showDropdown && !this.state.showMembersPopUp) return;
        if (this.actionMenuRef && this.actionMenuRef.current && !this.actionMenuRef.current.contains(event.target)) {
            this.setState({
                showDropdown: false
            })
        } else if (this.memberListRef && this.memberListRef.current && !this.memberListRef.current.contains(event.target)) {
            this.setState({
                showMembersPopUp: false
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { data } = this.props;
        if (prevProps.data.event.id !== data.event.id) {
            getGameMembers(data.event.id).then(resp => {
                if (resp.data.success) {
                    this.setState({ members: resp.data.data })
                }
            });
        }
    }

    handleStart() {
        const { data: { eventId } } = this.props;
        this.setState({
            disableStart: true,
            enableButton: false,
            errorMsg: null
        });
        axios.get(`/api/v1/event/start-event/${eventId}`).then((response) => {
            this.setState({
                disableStart: false,
                enableButton: true
            });
            if (!response.data.success) {
                this.setState({ errorMsg: response.data.message })
                return;
            }
            this.props.getRoomListAction(eventId)
            navigateX(`/event/${eventId}/feed`);
        });
    }

    handleChangeStatus() {
        const { data } = this.props;
        this.setState({ errorMsg: null })
        data.status = 'Joined';
        updateInviteStatus(data).then((resp) => {
            if (!resp.data.success) {
                this.setState({ errorMsg: 'Error in updating invite status' })
                return;
            }
            getUserInviteDetails().then((e) => {
                this.props.getRoomListAction(data.event.id)
            })
        });
    }

    handleEventJoin() {
        const { data } = this.props;
        axios.post(`/api/v1/event/join`, {
            eventId: data.event.id,
        }).then((resp) => {
			if (resp.data.success) {
                data.status = resp.data?.data?.status;
                data.eventId = resp.data?.data?.eventId;
                data.userId = resp.data?.data?.userId;
                getUserInviteDetails().then((e) => {
                    this.props.getRoomListAction(data.event.id)
                })
			} else {
                this.setState({ errorMsg: resp.data.message })
                return;
            }
		});
    }

    handleEndGame() {
        const { data } = this.props;
        endGame(data.event.id).then((resp) => {
            if (resp.data.success) {
                document.location.href = '/dashboard';
            }
        });
    }

    showMembers() {
        this.setState({ showMembersPopUp: true })
    }

    memberPopUp() {
        const { members } = this.state;
        return (
            <div className='te-member-list-wrap'>
                {members &&
                    members.map((data, i) => (
                        <div key={i} className="w3-row te-game-text-content" style={{ display: 'flex', marginBottom: "5px" }}>
                            <span className="te-text-wrap te-member-name" title={data?.user?.name}>{data?.user?.name}</span>
                        </div>
                    ))}
            </div>
        )
    }

    copyToClipboard = value => {
        navigator.clipboard.writeText(value);
        this.setState({ copyText: 'Event URL copied to clipboard' });

        setTimeout(() => {
            this.setState({ copyText: '' });
        }, 3000);
    };

    render() {
        const { days, hours, minutes, seconds, disableStart, enableButton, errorMsg, members, showEndGame, showMembersPopUp, copyText, showDropdown } = this.state;
        const { data, data: { status, event: { eventId, status: eventStatus, data: { eventGames: gameRound } } }, adminUser, type } = this.props;

        const isEventCompleted = isEventCompleteOrEnded(eventStatus);
        const avatarColors = ['#E4E29C', '#EEA7A7', '#8BACC3', '#AEF4A7', '#7CE9E9']
        return (
            <>
                <div className='w3-row' style={{ position: 'relative' }}>
                    <div className='star-icon' style={{ position: 'absolute', top: '0', right: '-40px' }}>
                        <img src={TwinkleStar} width='65%' />
                    </div>
                    <div className='w3-col m8'>
                        <div className="w3-row">
                            <div className="w3-row" style={{ paddingTop: '7px' }}>
                                <span className='te-event-card-title'>{data.event.gameName}</span><br />
                                <span className="w3-left time-stamp">
                                    {moment(Number(data.event.startTime)).format('DD MMM YYYY, hh:mm A')}
                                </span>
                            </div>
                        </div>
                        <div className="te-game-counter">
                            <p className='active-events-game-card-description' title={data.event.eventSummary}>
                            {data.event.eventSummary}
                            </p>
                            {!type ? <div /> :
                                <div className="w3-panel w3-round-large w3-padding te-counter-panel te-textcrusive-20 te-text-white w3-center" style={{ fontSize: "28px", color: '#FFC107' }}>
                                    {data.event.teams && data.event.teams[0] ? `Won by ${data.event.teams[0].teamName}` : `Not played`}
                                </div>
                            }

                            <div className="te-game-text-content" style={{ position: 'relative' }}>
                                <div
                                    className="w3-row"
                                    style={{ display: 'flex', alignItems: 'center' }}
                                >
                                    <div className="m5 s12 w3-center te-game-text-content">
                                        {eventStatus == 'Ended' && <p className="w3-text-red game-started">Game Ended</p>}
                                        {!type && eventStatus != 'Ended' ? <div className={adminUser ? 'te-admin-user' : ''}>
                                            {!status && <button
                                                className={`w3-button te-button-primary te-event-btn w3-round-xxlarge`}
                                                onClick={this.handleEventJoin.bind(this)}
                                            >Join Event
                                                <img src={RightArrow} style={{ paddingLeft: '10px' }} />
                                            </button>}

                                            {status == 'Invited' ?
                                                <button
                                                    className={`w3-button te-button-primary te-event-btn w3-round-xxlarge`}
                                                    onClick={this.handleChangeStatus.bind(this)}
                                                >Join Event
                                                    <img src={RightArrow} style={{ paddingLeft: '10px' }} />
                                                </button>
                                                :
                                                null
                                            }
                                            {(status && status != 'Invited') &&
                                                enableButton && <>
                                                    {(disableStart) ?
                                                        <button
                                                            className={`w3-button te-button-primary te-event-btn w3-round-xxlarge`}
                                                            disabled={disableStart}>
                                                            <span>{days}d : {hours}h : {minutes}m : {seconds}s </span>
                                                        </button>
                                                        :
                                                        <button
                                                            className={`w3-button te-button-primary te-event-btn w3-round-xxlarge`}
                                                            disabled={disableStart}
                                                            onClick={this.handleStart.bind(this)}>
                                                            Launch
                                                            <img src={RightArrow} style={{ paddingLeft: '10px' }} />
                                                        </button>
                                                    }
                                                </>  
                                            }
                                        </div> :
                                            <div className="te-game-text-content w3-center" style={(data.event.teams && data.event.teams[0]) ? {} : { visibility: 'hidden' }}>
                                                <A href={`/event/${data.event.id}/home`}
                                                    type="submit"
                                                    className="w3-button w3-round-xxlarge te-button-transparent-dark"
                                                    style={{ textDecoration: 'none' }}>
                                                    View Results
                                                </A>
                                            </div>}
                                    </div>
                                    <div className='pro-pic-wrap w3-hide-small'>
                                        <ul className="te-list s10 m5" style={{ position: 'relative' }}>
                                            {members && members.filter((item, idx) => idx < 2).map((member, i) => (
                                                <li key={member.emailId}
                                                    title={member?.user?.name}
                                                    className="te-profile-list-item w3-circle" style={member?.user?.profilePic ? {} : { backgroundColor: avatarColors[i] }}>
                                                    {
                                                        member?.user?.profilePic ?
                                                            <img
                                                                className='w3-circle'
                                                                src={member.user.profilePic} width={'100%'}
                                                                height={'100%'}
                                                                style={{ objectFit: 'cover' }}
                                                            ></img> :
                                                            <div>{member?.user?.name.charAt(0).toUpperCase()}</div>
                                                    }
                                                </li>
                                            )
                                            )}
                                            {members.length > 2 &&
                                                (<div className="te-game-text-content-xs te-cursor-pointer" style={{ lineHeight: '40px' }}
                                                    onClick={() => this.showMembers()}
                                                >
                                                    &nbsp;+{members.length - 2}&nbsp;more
                                                </div>)}

                                            {showMembersPopUp ? (
                                                <div className="w3-padding w3-border w3-round w3-medium te-dropdown-event-card te-gallery-dropdown-event-card te-members"
                                                    ref={this.memberListRef}
                                                >
                                                    {this.memberPopUp()}
                                                </div>
                                            ) : null}
                                        </ul>
                                    </div>
                                    <div className='more-icon w3-hide-small'>
                                        {adminUser && !isEventCompleted && <img src={MoreIcon} onClick={() => this.setState({ showDropdown: !showDropdown })} />}
                                        {(showDropdown && adminUser) &&
                                            <div className='action-menu'>
                                                <div className={`w3-show w3-bar-block w3-border w3-round w3-medium te-dropdown-event-card te-gallery-dropdown-event-card`} ref={this.actionMenuRef}>
                                                    {(showEndGame) && <p onClick={this.handleEndGame.bind(this)}>End Game</p>}
                                                    {(eventStatus == 'Created') && <p><a href={`/select-rounds/${data.event.id}`} style={{ textDecoration: 'none' }}>Invite People</a></p>}
                                                    {(!showEndGame && eventStatus != 'Started' && type != 'previous') && <p onClick={() => this.copyToClipboard(data.event.url)}>Copy Invite Link</p>}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div style={{ paddingLeft: '10px' }} className="w3-hide-medium w3-hide-large w3-display-right">
                                        <a href={`/select-rounds/${data.event.id}`} style={{ textDecoration: 'none' }}>
                                            <button className="w3-button te-button-share-event body-text-small w3-round-xxlarge">
                                                <img src={ShareIconDots} />
                                            </button>
                                        </a>
                                    </div>
                                    <div style={{ display: 'flex' }} className="w3-hide-medium w3-hide-large">
                                        <img src={MembersIcon} style={{ paddingLeft: '10px', paddingRight: '3px' }} />
                                        {members.length}
                                    </div>
                                </div>
                                {copyText != '' && <span className="w3-row w3-right w3-text-green te-clipboard">{copyText}</span>}
                            </div>

                            {errorMsg ? (
                                <Popup
                                    open={true}
                                    message={errorMsg}
                                />
                            ) : null}
                        </div>
                    </div>
                    <div className='w3-col m4 w3-center'>
                        <div className="te-game-text-content w3-hide-small" style={{ padding: '0px 35px', paddingTop: '30px' }}>
                            <div className="w3-row">
                                <div className='w3-col te-round-info' style={{ display: 'flex', justifyContent: 'center' }}>
                                    {gameRound && gameRound.map((gameRound, i) => {
                                        if (i > 2) {
                                            return;
                                        }

                                        let cardInfo = null;
                                        if (gameRound?.game?.theme[0]) {
                                            cardInfo = {
                                                color: gameRound.game.theme[0].textColor,
                                                bgColor: gameRound.game.theme[0].backgroundColor,
                                                background: gameRound.game.themeImage
                                            };
                                        }
                                        if (!cardInfo) {
                                            return null;
                                        }

                                        return (
                                            <div key={gameRound.gameId} className={`te-game-round-card w3-round-large te-game-text-xs ${cardInfo.color} ${cardInfo.bgColor} anim-card-${i + 1}`}
                                                title={gameRound.roundName}
                                            >
                                                <img src={cardInfo.background} />
                                                <p className='flip-card-title'>{(gameRound.roundName.length > 25) ? gameRound.roundName.slice(0, 22) + `...` : gameRound.roundName}</p>
                                            </div>
                                        )
                                    })}
                                </div>
                                {(gameRound && Object.keys(gameRound).length > 3) ?
                                    (<div className="w3-col te-cursor-pointer" style={{ fontSize: '12px', marginBottom: '10px', color: '#8e90a8' }}>
                                        &nbsp;+{Object.keys(gameRound).length - 3}&nbsp;more
                                    </div>) : (
                                        <div className='w3-col' style={{ marginBottom: '10px' }}>&nbsp;</div>
                                    )
                                }
                            </div>
                        </div>
                        <div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default connect(null, { getRoomListAction })(EventCard);