import { combineReducers, createStore, applyMiddleware} from 'redux';
import { actions } from './actions';

import { celebrateReducer, sceneReducer, streamReducer } from './celebrate';
import { chatReducer, getRoomMessagesAction, refetchMessageAction } from './chat';
import { feedReducer } from './feed';
import { channelReducer, reconnectAction, socketIO } from './channel'; 
import thunk from 'redux-thunk';
import { eventReducer } from './event';

const network = (state = {
	pinStream: null,
}, changeAction) => {
	switch (changeAction.type) {
		case actions.FETCH_REQUEST: {
			const obj = Object.assign({}, state);
			obj[changeAction.key] = {
				isFetching: true,
				error: false,
			};
			return obj;
		}
		case actions.FETCH_REQUEST_SUCCESS: {
			const obj = Object.assign({}, state);
			obj[changeAction.key] = {
				isFetching: false,
				error: false,
				data: changeAction.data,
			};
			return obj;
		}
		case actions.FETCH_REQUEST_ERROR: {
			const obj = Object.assign({}, state);
			obj[changeAction.key] = {
				isFetching: false,
				error: true,
				errorMessage: changeAction.error,
			};
			return obj;
		}

		case actions.SET_DATA: {
			const obj = Object.assign({}, state);
			obj[changeAction.key] = changeAction.value;
			return obj;
		}

		default:
			return state;
	}
};

const reducer = combineReducers({
	network,
	stream:streamReducer,
	channels: channelReducer,
	celebrate: celebrateReducer,
	scene: sceneReducer,
	chat: chatReducer,
	feed: feedReducer,
	event: eventReducer,
});


export const store = createStore(reducer, applyMiddleware(thunk));

socketIO.on("connect", () => {
	console.log('reconnected - ', socketIO.connected); // true
	store.dispatch(reconnectAction());
	store.dispatch(refetchMessageAction());
});
