import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getUserGameDetails,
    getUserPreviousGameDetails,
} from "../../Redux/api";

import { setChatEventAction } from '../../Redux/chat';
import { setEventData } from '../../Redux/event'
import { A } from "hookrouter";
import EventCard from './EventCard';
import { userDataMapper } from '../../Redux/mappers';
import axios from "axios";
import FungamesHome from '../FunGames/FungamesHome';
import { navigateX } from "../../helpers";
import longArrow from '../../images/long-arrow.png';
import { adminOrManagerUserCheck } from "../../helpers";


const DashboardNew = ({ filter }) => {
    const dispatch = useDispatch();
    const user = useSelector(st => userDataMapper(st));

    const {
		chat: { expand },
        event: { eventDetail }
	} = useSelector(state => ({ chat: state.chat, event: state.event }));

    const [gameData, setGameData] = useState(null);
    const [prevData, setPrevData] = useState([]);
    const [funUniverseGame, setFunUniverseGame] = useState(undefined);
    const [enableCreateGame, setEnableCreateGame] = useState(false)
    const active = filter != 'previous';

    useEffect(async () => {
        fetchEventData();

        const redirectUrl = sessionStorage.getItem('inviteRedirect');
        if (redirectUrl) {
            window.location.href = redirectUrl;
        }

        if (localStorage.getItem('on-boarding') != 'hide') {
            macawSDK.triggerFlow('quick-tour-dashboard');
        }
        
        return () => {
            sessionStorage.removeItem('inviteRedirect');
        }
    }, [1])

    useEffect(() => {
        if (user && eventDetail && eventDetail.groupId) {
            setEnableCreateGame(adminOrManagerUserCheck(user, eventDetail))
        }
    }, [eventDetail, user])


    const fetchEventData = () => {
        getUserGameDetails().then(resp => {
            if (resp.data.success) {
                let arr = resp.data.data;
                //extracting fun-universe event from event list
                const funUniverse = arr.find((el) => el.event.uniqueId == `ev-${el.event.groupId}`);
                setFunUniverseGame(funUniverse);
                if (funUniverse?.event) {
                    dispatch(setChatEventAction(funUniverse.event.id));
                    dispatch(setEventData(funUniverse.event));
                }

                arr = arr.sort((a, b) => b.event.startTime - a.event.startTime).filter(el => el.event.uniqueId !== `ev-${el.event.groupId}`);
                setGameData(arr);
            }
        });
        getUserPreviousGameDetails().then(resp => {
            if (resp.data.success) {
                const gameArr = resp.data.data;
                setPrevData(gameArr)
            }
        });
    }


    if (user === null) {
        return <div className="margin-top-40 t-margin-bottom-50">
            <div className="w3-row w3-row-padding w3-panel w3-margin-bottom w3-round-xlarge w3-padding">
                <div className="w3-col m12 s12">
                    <div className="w3-row w3-center margin-top-100 t-margin-bottom-100">
                        <p className="te-game-text">Loading...</p>
                    </div>
                </div>
            </div>
        </div>
    }
    if (user === false) {
        return (
            <Fragment>
                <div className="margin-top-40 t-margin-bottom-50">
                    <div className="w3-row w3-row-padding w3-panel w3-margin-bottom w3-round-xlarge w3-padding">
                        <div className="w3-col m12 s12">
                            <div className="w3-row w3-center margin-top-100 t-margin-bottom-100">
                                <p className="te-game-text">Please Login to join to the Invited games.</p>
                                <p className="te-game-text-content margin-top-30">Note- For Users registered through Invite Code,<br /> Password is the 6 digit Invite Code</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }

    return (
        <Fragment>
            <div className="w3-panel w3-round-xlarge te-dashboard te-event-list w3-margin-bottom">
                <div className="w3-col m12 s12">
                    <div className="w3-row w3-display-container">
                        <div className="te-active-events-top-section w3-padding">
                        <div className="w3-padding-16">
                            <span className="headline-text-large ">{active ? 'My Active Events' : 'My Previous Events'}</span>
                        </div>
                        {enableCreateGame && active && <div className="te-align-middle"> <button
                            id="new-event-button"
                            className=" w3-button te-button-new-event body-text-small w3-round-xxlarge "
                            onClick={() => navigateX(`/select-rounds`)} 
                        >
                           <i className='ion-plus'></i> New Event
                        </button> </div>}
                        </div>
                    </div>
                    <div id="active-events"></div>
                    {active ?
                        (gameData != null) && Object.keys(gameData).length == 0 ?
                            <div className="w3-center te-align-middle te-no-active-height"  >
                               {(enableCreateGame) ? (
                                   <div className="body-text-large w3-opacity te-color-5 w3-padding-large" style={{position:"relative"}} > No events are created.<br/> Click on the "<b className="te-text-secondary-18" >New Event</b>" button to create a new Event. 
                                        <div className="te-no-active-arrow-img w3-hide-small">
                                            <img src={longArrow} />
                                        </div>
                                    </div>  
                               ) :
                               <div className="body-text-large w3-opacity te-color-5 w3-padding-large" style={{position:"relative"}} >
                                   No events to list.
                               </div>  
                               }
                                  
                            </div> : (
                                <div>
                                    <div className="w3-row te-game-card-main t-margin-bottom-30">
                                        {gameData &&
                                            gameData.map((data, i) =>
                                            (
                                                <div className={`w3-col m12 s12 ${expand ? 'l12' : 'l6'}`} key={i}> {/* {`name_${data.event.uniqueId}`}> */}
                                                    <div className="te-game-card w3-margin w3-round-xlarge w3-padding-large">
                                                        <EventCard
                                                            setChat={(eventId) => {
                                                                dispatch(setChatEventAction(eventId))
                                                            }}
                                                            data={data}
                                                            gameTags={[]}
                                                            adminUser={user.user_roles.some(role => data.event.groupId == role.groupId && (role.roleId === 'admin' || role.roleId === 'manager'))}
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            ) :
                        (
                            <div>
                                <div className="te-game-text-content w3-margin-left">
                                    Games you have already played will appear here.
                                </div>
                                <div className="w3-row w3-margin-top te-game-card-main t-margin-bottom-30">
                                    {prevData &&
                                        prevData.map((data, i) => (
                                            <div className={`w3-col s12 ${expand ? 'l12' : 'l6'}`} key={i}>
                                                <div className="te-game-card w3-margin w3-round-xlarge w3-padding-large">
                                                    <EventCard
                                                        data={data}
                                                        gameTags={[]}
                                                        adminUser={user.user_roles.some(role => data.event.groupId == role.groupId && role.roleId === 'admin')}
                                                        type={'previous'} />
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        )}
                </div>
            </div>
        </Fragment >
    );
}


export default DashboardNew


