import React from 'react'; 
import { navigateX } from '../../helpers';

class Popup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: this.props.open,
        }
    }

    handleClose = () => {
        const navigator = this.props.navigate;
        const { handleOnClose } = this.props;
        this.setState({
             isOpen: false
        });
        handleOnClose && handleOnClose();
       if (navigator) {
           navigateX(navigator);
       } 
    }

    render() {
        const { message } = this.props;
        return (
            <div>
                <div>
                <div
                    open={this.state.isOpen}
                    onClose={() => this.handleClose()}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <span id="alert-dialog-title">{"Alert!"}</span>
                    <div 
                        style={{minWidth:"300px"}}>
                        <span id="alert-dialog-description">
                            {message}
                        </span>
                    </div>
                    <div>
                        <button onClick={() => this.handleClose()} color="primary">
                            Ok
                     </button>
                    </div>
                </div>
                </div>
            </div>
        )
    }
}

export default Popup;