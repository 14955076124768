import queryString from "querystring";
import axios from "axios";
import endpoints from "./endpoints";



axios.interceptors.request.use(function (config) {
	// Do something before request is sent
	const token = localStorage.getItem("accessToken");
	if (token && config.url.indexOf("/api") == 0) {
		config.headers["Authorization"] = `Bearer ${token}`;
	}
	return config;
}, function (error) {
	// Do something with request error
	return Promise.reject(error);
});



export const triggerRequest = (key, path = [], params = {}) => {
	const request = Object.assign({}, endpoints[key]);
	if (path.length > 0) {
		request.path += "/" + path.join("/");
	}

	if (request.method === undefined || request.method === "GET") {
		request.method = "GET";
		const qs = queryString.stringify(params);
		if (qs !== "") {
			request.path += `?${qs}`;
		}
	}
	return axios[request.method.toLowerCase()](request.path, params)
		.then((response) => { 
			return response;
		})
		.catch((error, response) => {
			if (error?.response?.status == 401) {
				localStorage.removeItem('accessToken');
				//hard refresh to org-sign.
				if (!['org-signin', 'org-verify-account'].includes(window.location.pathname.replaceAll('/', ''))) {
					window.location.href = '/org-signin';
				}
			}
			return error.response;
		});

};
export const handleLogout = () => {
	localStorage.clear();
	sessionStorage.clear();
	document.location.href = '/';
}



// TRIGGER
export const postInviteUserList = (createInviteList) => {
	return triggerRequest("inviteUserList", [], { createInviteList });
};

export const postInviteAgain = (data) => {
	return triggerRequest("inviteAgain", [], { data });
};


export const gameUrlLink = (eventId) => {
	return triggerRequest("getGameUrl", [eventId], {});
};


export const getTriviaList = () => {
	return triggerRequest("getCategory", []);
};

export const postForgotPassword = (form) => {
	return triggerRequest("postForgotPassword", [], form);
};
export const postResetPassword = (form) => {
	return triggerRequest("postResetPassword", [], form);
}

export const sendLead = (email) => {
	return triggerRequest('sendLead', [], email);
}

export const orgUserDetails = (groupId) => {
	return triggerRequest("orgUserDetails", [groupId], {});
};

export const changePassword = (form) => {
	return triggerRequest('changePassword', [], form);
};

export const changePswdUserInfo = (resetToken) => {
	return triggerRequest("changePswdUserInfo", [resetToken], {});
};

export const postCreateEvent = (form) => {
	return triggerRequest("postCreateEvent", [], form);
};

export const postUpdateEvent = (form) => {
	return triggerRequest("postUpdateEvent", [], form);
};

export const getGameDetails = (eventId) => {
	return triggerRequest("getGameDetails", [eventId]);
};

export const getUserGameDetails = () => {
	return triggerRequest("getUserGameDetails", [], {});
};

export const getEventDetails = (eventId) => {
	return triggerRequest("getEventDetails", [eventId], {});
};


export const endGame = (eventId) => {
	return triggerRequest("endGame", [eventId], {});
};
export const registerOAuth = (form) => {
	return triggerRequest("registerOAuth", [], form);
};


export const sendVerificationCode = (email) => {
	return triggerRequest('sendVerificationCode', [], email);
}

export const emailVerification = (form) => {
	return triggerRequest('emailVerification', [], form);
}

export const orgCreateAccount = (form) => {
	return triggerRequest('orgCreateAccount', [], form);
}


export const updateInviteStatus = (data) => {
	return triggerRequest("updateInviteStatus", [], { data });
};

export const getUserInviteDetails = () => {
	return triggerRequest("getUserInviteDetails", [], {});
};


export const postSingleUser = (formdata) => {
	return triggerRequest("inviteUser", {}, formdata);
};

export const getGameMembers = (eventId) => {
	return triggerRequest("getGameMembers", [eventId], {});
};

export const getEventInvites = (eventId) => {
	return triggerRequest("getEventInvites", [eventId], {});
};

export const getUserPreviousGameDetails = () => {
	return triggerRequest("getUserPreviousGameDetails", [], {});
};


export const getAdminUsers = (groupId) => {
	return triggerRequest("getAdminUsers", [groupId], {});
};
 

export const getInvitedAdminUsersInfo = (token) => {
	return triggerRequest("getInvitedAdminUsersInfo", [token], {});
};
export const postAdminAccept = (formdata) => {
	return triggerRequest("postAdminAccept", {}, formdata);
};


export const inviteAdminUser = (invitedAdminUser) => {
	return triggerRequest("inviteAdminUser", [], invitedAdminUser);
};


export const postLogin = (form) => {
	return triggerRequest("postLogin", [], form);
};

export const updateRole = (formdata) => {
	return triggerRequest("updateRole", [], formdata);
};

export const updateProfile = (form) => {
	return triggerRequest('updateProfile', [], form);
};

export const postJoinInvite = (inviteCode) => {
	return triggerRequest("joinInvite", {}, { inviteCode })
}

export const postRegister = (form) => {
	return triggerRequest("postRegister", [], form);
};
 

export const getTokenStatus = (token) => {
	return triggerRequest("validateToken", [token], {});
};

export const getAllUsersList = (orgId) => {
	return triggerRequest("getAllUsersList", [orgId], {});
};
export const getAllCalenderEventsList = (orgId) => {
	return triggerRequest("getAllCalenderEventsList", [orgId], {});
};
export const addUsersInfoToDB = (usersListToBeAdded, orgId) => {
	return triggerRequest("addUsersInfoToDB", [], { usersListToBeAdded, orgId});
};
export const addCalenderEventsToDB = (eventsData, orgId) => {
	return triggerRequest("addCalenderEventsToDB", [], { eventsData, orgId});
};

export const deleteUserInfo = (usersToBeDeleted, orgId) => {
	return triggerRequest("deleteUserInfo", [], { usersToBeDeleted, orgId});
};

export const restoreUserStatus = (usersToBeRestored, orgId) => {
	return triggerRequest("restoreUserStatus", [], { usersToBeRestored, orgId});
};

 
 
 