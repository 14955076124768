
import { socketIO } from "./channel";
import { userDataMapper } from "./mappers";
import { makeId } from '../helpers';

navigator.getUserMedia = (navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia || navigator?.mediaDevices?.getUserMedia);

export const streamReducer = (state = {
    videoStream: null,
    audioStream: null,
    screenStream: null,
    mediaStream: null,
    audioOn: false,
    videoOn: false,
    screenOn: false,
    p2pList: [],
    celebrateStatus: null,
    userMediaList: {},
    userScreenList: {},
}, action) => {
    let nextState = state;
    if (action.type === 'media-stream') {
        nextState = Object.assign({}, state, {
            mediaStream: action.data.stream,
            videoOn: action.data.video ? true : false,
            audioOn: action.data.audio ? true : false
        });
    } else if (action.type == 'screen-stream') {
        nextState = Object.assign({}, state, {
            screenStream: action.data,
            screenOn: action.data ? true : false
        })
    } else if (action.type == 'peer-connection') {
        let newList = [];
        if (action.data.state == 'answered') {
            newList = state.p2pList.filter(item => !(item.state == 'answered' && item.type == action.data.type &&
                item.userId == action.data.userId));
            newList = newList.concat([action.data]);
        } else {
            newList = state.p2pList.filter(item => !((item.state == 'connected' || item.state == 'requesting') && item.type == action.data.type &&
                item.userId == action.data.userId));
            newList = newList.concat([action.data]);
        }

        nextState = Object.assign({}, state, {
            p2pList: newList
        })
    } else if (action.type == 'celebrate-status') {
        nextState = Object.assign({}, state, {
            celebrateStatus: action.data
        })
    } else if (action.type == 'user-media-list') {
        nextState = Object.assign({}, state, {
            userMediaList: action.data
        })
    } else if (action.type == 'user-screen-list') {
        nextState = Object.assign({}, state, {
            userScreenList: action.data
        })
    }
    return nextState;
}

export const celebrateReducer = (state = {
    items: [{
        type: 'person',
        img: '',
    }, {
        type: 'bg_image',
        img: '',
    }, {
        type: 'head',
        img: '',
    }, {
        type: 'neck',
        img: '',
    }, {
        type: 'lips',
        img: '',
    }, {
        type: 'ear',
        img: '',
    }, {
        type: 'beard',
        img: '',
    }, {
        type: 'moustache',
        img: '',
    }, {
        type: 'eyes',
        img: '',
    }
    ],

}, action) => {
    return state;
}

export const sceneReducer = (state = [{
    type: 'person',
    img: '',
}, {
    type: 'bg_image',
    img: '',
}, {
    type: 'head',
    img: '',
}, {
    type: 'neck',
    img: '',
}, {
    type: 'lips',
    img: '',
}, {
    type: 'ear',
    img: '',
}, {
    type: 'beard',
    img: '',
}, {
    type: 'moustache',
    img: '',
}, {
    type: 'eyes',
    img: '',
}

], action) => {
    return state;
}

export const startMediaStreamAction = (type) => (dispatch, getState) => {
    const state = getState();
    const { stream : { audioOn, videoOn } } = state;

    const { connections } = window;

    if (type === 'screen') {
        navigator.mediaDevices.getDisplayMedia({
            video: true,
            audio: false,
        }).then(stream => {
            try {
                window.localScreenStream.getTracks().forEach(track => track.stop())
            } catch(e) { console.log(e) }

            dispatch({
                type: 'screen-stream',
                data: stream
            });
            dispatch(updateUserStatus());

            window.localScreenStream = stream;

            for (let id in connections) {
                if (id === socketIO.id) continue

                stream.getTracks().forEach(function (track) {
                    connections[id].addTrack(track);
                });

                connections[id].createOffer().then((description) => {
                    connections[id].setLocalDescription(description)
                        .then(() => {
                            socketIO.emit('signal', {
                                toId: id,
                                message: JSON.stringify({ 'sdp': connections[id].localDescription })
                            })
                        })
                        .catch(e => console.log(e))
                }).catch(e => console.log(e))
            }

            stream.getTracks().forEach(track => track.onended = () => {
                dispatch({
                    type: 'screen-stream',
                    data: null
                });
                dispatch(updateUserStatus());
            })
        }).catch(ex => {
            alert(ex.message);
        });
    } else if (type === 'audio') {
        navigator.getUserMedia({
            video: videoOn,
            audio: true
        }, stream => {
            try {
                window.localStream.getTracks().forEach(track => track.stop())
            } catch(e) { console.log(e) }

            dispatch({
                type: 'media-stream',
                data: {
                    stream,
                    video: videoOn,
                    audio: true
                }
            });
            dispatch(updateUserStatus());

            window.localStream = stream;

            for (let id in connections) {
                if (id === socketIO.id) continue

                // connections[id].addStream(stream);

                stream.getTracks().forEach(function (track) {
                    connections[id].addTrack(track, stream);
                });

                connections[id].createOffer().then((description) => {
                    connections[id].setLocalDescription(description)
                        .then(() => {
                            socketIO.emit('signal', {
                                toId: id,
                                message: JSON.stringify({ 'sdp': connections[id].localDescription })
                            })
                        })
                        .catch(e => console.log(e))
                }).catch(e => console.log(e))
            }

            stream.getTracks().forEach(track => track.onended = () => {
                dispatch({
                    type: 'media-stream',
                    data: {
                        stream: null,
                        video: false,
                        audio: false
                    }
                });
                dispatch(updateUserStatus());
            })
        }, error => {
            console.error(error);
        })
    } else if (type === 'video') {
        navigator.getUserMedia({
            video: true,
            audio: audioOn
        }, stream => {
            try {
                window.localStream.getTracks().forEach(track => track.stop())
            } catch(e) { console.log(e) }

            dispatch({
                type: 'media-stream',
                data: {
                    stream,
                    video: true,
                    audio: audioOn
                }
            });
            dispatch(updateUserStatus());

            window.localStream = stream;

            for (let id in connections) {
                if (id === socketIO.id) continue

                // connections[id].addStream(stream);

                stream.getTracks().forEach(function (track) {
                    connections[id].addTrack(track, stream);
                });

                connections[id].createOffer().then((description) => {
                    connections[id].setLocalDescription(description)
                        .then(() => {
                            socketIO.emit('signal', {
                                toId: id,
                                message: JSON.stringify({ 'sdp': connections[id].localDescription })
                            })
                        })
                        .catch(e => console.log(e))
                }).catch(e => console.log(e))
            }

            stream.getTracks().forEach(track => track.onended = () => {
                dispatch({
                    type: 'media-stream',
                    data: {
                        stream: null,
                        video: false,
                        audio: false
                    }
                });
                dispatch(updateUserStatus());
            })
        }, error => {
            alert(error.message);
        });
    }
}


export const setCelebrateStream = (status=false, dataStream=null) => (dispatch, getState) => {
    console.log('called setCelebrateStream - ');
    const state = getState();
    const { stream } = state;
    const { mediaStream, audioOn, videoOn } = stream;
    const { connections } = window;

    if (!connections) {
        return;
    }

    if (dataStream && status == true) {
        let videoTrack = dataStream.getVideoTracks()[0];

        navigator.getUserMedia({
            video: true,
            audio: audioOn
        }, newStream => {
            try {
                window.localStream.getTracks().forEach(track => track.stop())
            } catch(e) { console.log(e) }

            newStream.removeTrack(newStream.getVideoTracks()[0]);
            newStream.addTrack(videoTrack);

            window.localStream = newStream;

            const selfVideo = document.getElementById('self-video');
            if (selfVideo) {
                selfVideo.srcObject = newStream;
            }

            for (let id in connections) {
                if (id === socketIO.id) continue

                newStream.getTracks().forEach(function (track) {
                    connections[id].addTrack(track, newStream);
                });

                connections[id].createOffer().then((description) => {
                    connections[id].setLocalDescription(description)
                        .then(() => {
                            socketIO.emit('signal', {
                                toId: id,
                                message: JSON.stringify({ 'sdp': connections[id].localDescription })
                            })
                        })
                        .catch(e => console.log(e))
                }).catch(e => console.log(e))
            }

            newStream.getTracks().forEach(track => track.onended = () => {
                dispatch({
                    type: 'media-stream',
                    data: {
                        stream: null,
                        video: false,
                        audio: false
                    }
                });
                dispatch(updateUserStatus());
            })
        }, error => {
            alert(error.message);
        });

        dispatch({
            type: 'celebrate-status',
            data: {
                status,
                dataStream
            }
        });
    } else {
        dispatch({
            type: 'celebrate-status',
            data: {
                status,
                dataStream
            }
        });
        if (!videoOn) {
            return;
        }

        dispatch(startMediaStreamAction('video'));
    }
}

export const updateUserStatus = () => (dispatch, getState) => {
    const {
        chat: {
            selectedRoom: {
                id: roomId
            }
        },
        stream: {
            audioOn,
            videoOn,
            screenOn
        }
    } = getState();
    socketIO.emit('member-info', {
        roomId,
        userData: {
            videoStream: videoOn,
            audioStream: audioOn,
            screenStream: screenOn
        }
    })
}

export const stopMediaStreamAction = (type) => async (dispatch, getState) => {
    const state = getState();
    const user = userDataMapper(state)
    const userId = user.id;
    const { stream: { mediaStream, screenStream, audioOn, videoOn } } = state;

    if (type === 'screen') {
        dispatch({
            type: 'screen-stream',
            data: null
        });
        const tracks = screenStream.getTracks();
        for (const track of tracks) {
            track.stop();
        }
        dispatch(updateUserStatus());
    } else if (type === 'video') {
        const tracks = mediaStream.getTracks();
        for (const track of tracks) {
            if (track.kind == 'video') {
                track.stop();
            }
        }
        try {
            window.localStream.getTracks().forEach(track => track.stop())
        } catch(e) { console.log(e) }
        
        dispatch({
            type: 'media-stream',
            data: {
                stream: null,
                video: false,
                audio: audioOn
            }
        });
        dispatch(updateUserStatus());
    } else if (type === 'audio') {
        const tracks = mediaStream.getTracks();
        for (const track of tracks) {
            if (track.kind == 'audio') {
                track.stop();
            }
        }
        dispatch({
            type: 'media-stream',
            data: {
                stream: mediaStream,
                video: videoOn,
                audio: false
            }
        });
        dispatch(updateUserStatus());
    }
}

var add = (pc, can) => {
    const strCan = JSON.stringify(can);
    console.log(strCan);
    pc.addIceCandidate(JSON.parse(strCan)).catch(ex => console.log(ex));
}


export const createConnection = (from = null, to = null, type = null, streamId) => {
    const IP = 'd.ceegees.in';
    const PORT = '3478'; //33533

    const iceServers = [{
        urls: `turn:${IP}:${PORT}?transport=tcp`,
        username: 'cgs',
        credential: 'cgs'
    }];

    const peer = new RTCPeerConnection({
        iceServers
    });

    peer.onicecandidate = e => {
        if (!e.candidate || !from || !to) {
            return;
        }
        const msg = {
            to,
            type,
            from,
            candidate: e.candidate,
            streamId
        };

        socketIO.emit('c-candidate-update', msg);
    };

    peer.oniceconnectionstatechange = e => async (dispatch, getState) => {
        console.log(
            `connection state change ${from}-${to}-${type}: ${e.target.iceConnectionState}`,
            e
        );

        if (e.target.iceConnectionState === 'failed' || e.target.iceConnectionState === 'disconnected') { 
            console.log('restarting ice');
            peer.restartIce();

            var configuration = {
                iceRestart: true,
                offerToReceiveAudio: true,// type === 'audio',
                offerToReceiveVideo: true, //Ptype === 'video' || type === 'screen'
            }
            const streamId = makeId();
            const offer = await peer.createOffer(configuration);
            await peer.setLocalDescription(offer);
            const data = {
                type,
                to: to,
                offer: peer.localDescription,
                streamId
            };
            socketIO.emit('c-offer', data);
        
            dispatch({
                type: 'peer-connection',
                data: {
                    state: 'requesting',
                    peer,
                    type,
                    userId: from,
                    streamId
                }
            })
        }
    };
    return peer;
}

export const createOffer = (toUserId, type) => async (dispatch, getState) => {
    console.log('creating offer');
    const state = getState();
    const user = userDataMapper(state)

    var configuration = {
        iceRestart: true,
        offerToReceiveAudio: true, // type === 'audio',
        offerToReceiveVideo: true, //Ptype === 'video' || type === 'screen'
    }

    const streamId = makeId();
    const peer = createConnection(user.id, toUserId, type, streamId);
    const offer = await peer.createOffer(configuration);
    await peer.setLocalDescription(offer);
    const data = {
        type,
        to: toUserId,
        offer: peer.localDescription,
        streamId
    };
    socketIO.emit('c-offer', data);

    dispatch({
        type: 'peer-connection',
        data: {
            state: 'requesting',
            peer,
            type,
            userId: toUserId,
            streamId
        }
    })
}

export const createAnswer = (data) => async (dispatch, getState) => {
    const state = getState();
    const user = userDataMapper(state)

    const { stream: {
        videoStream,
        audioStream,
        screenStream,
        audioOn,
        videoOn,
        screenOn,
        celebrateStatus
    } } = state;

    const peer = createConnection(user.id, data.from, data.type, data.streamId);

    if (videoOn && data.type === 'audio-video') {
        console.log('adding video tracks')

        if (celebrateStatus?.status === true && celebrateStatus?.dataStream) {
            let videoTrack = celebrateStatus.dataStream.getVideoTracks()[0];
            peer.addTrack(videoTrack, celebrateStatus.dataStream);
        } else {
            videoStream.getVideoTracks().forEach((track) => {
                peer.addTrack(track, videoStream);
            });
        }
    }

    if (audioOn && data.type === 'audio') {
        console.log('adding audio tracks')
        audioStream.getAudioTracks().forEach(async (track) => {
            peer.addTrack(track, audioStream);
        });
    }

    if (screenOn && data.type === 'screen') {
        screenStream.getVideoTracks().forEach(track => {
            peer.addTrack(track, screenStream);
        });
    }

    await peer.setRemoteDescription(data.offer);
    const answer = await peer.createAnswer();
    await peer.setLocalDescription(answer);
    console.log('sending answer', data);

    socketIO.emit('c-answer', {
        type: data.type,
        to: data.from,
        answer,
        streamId: data.streamId
    });

    dispatch({
        type: 'peer-connection',
        data: {
            state: 'answered',
            peer,
            type: data.type,
            userId: data.from,
            streamId: data.streamId
        }
    })
}

export const acceptAnswer = (data) => async (dispatch, getState) => {

    const state = getState();
    const { stream: {
        p2pList
    } } = state;

    const p2p = p2pList.find(item => item.streamId == data.streamId);
    if (!p2p) {
        return;
    }

    console.log('accepting answer', data);
    await p2p.peer.setRemoteDescription(data.answer);
    dispatch({
        type: 'peer-connection',
        data: {
            state: 'connected',
            type: data.type,
            peer: p2p.peer,
            userId: data.from,
            streamId: data.streamId
        }
    })
}

export const candidateUpdated = (data) => (dispatch, getState) => {
    const state = getState();
    const { stream: {
        p2pList
    } } = state;
    const p2p = p2pList.find(item => item.streamId == data.streamId);
    if (!p2p) {
        return;
    }

    console.log('server update', data.from);
    p2p.peer.addIceCandidate(data.candidate).catch(ex => {
        console.log('candidate update', ex);
    });
}


export const setUserMediaList = (data) => async (dispatch, getState) => {
    dispatch({
        type: 'user-media-list',
        data
    })
}

export const setUserScreenList = (data) => async (dispatch, getState) => {
    dispatch({
        type: 'user-screen-list',
        data
    })
}

