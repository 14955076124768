export default {


    //invites
    getUserInviteDetails: {
        path: '/api/v1/invite/get-user',
    },

    inviteUserList: {
        path: '/api/v1/invite/user-invite-list',
        method: 'POST'
    },

    inviteAgain: {
        path: '/api/v1/invite/user-invite-again',
        method: 'POST'
    },

    getGameUrl: {
        path: '/api/v1/invite/game-url'
    },

   

    joinInvite: {
        path: '/api/v1/invite/join-code',
        method: 'POST'
    },

    validateToken: {
        path: '/api/v1/invite/token-status'
    },

    getInvitedEmail: {
        path: '/api/v1/invite/get-user-email'
    },

    inviteUser: {
        path: '/api/v1/invite/user-invite',
        method: 'POST',
    },

    updateInviteStatus: {
        path: '/api/v1/invite/update',
        method: 'POST',
    },
     

    inviteAdminUser: {
        path: '/api/v1/invite/invite-admin-user',
        method: 'POST'
    },

    getInvitedAdminUsersInfo: {
        path: '/api/v1/invite/invited-admin-user-info',
        method: 'GET'
    },

    postAdminAccept: {
        path: '/api/v1/invite/admin-accept-invite',
        method: 'POST'
    },

    getAdminUsers: {
        path: '/api/v1/invite/admin-users',
        method: 'GET'
    },

    updateRole: {
        path: '/api/v1/invite/update-role',
        method: 'POST'
    },

    //create-game

    postCreateEvent: {
        path: '/api/v1/event/create',
        method: 'POST'
    },

    postUpdateEvent: {
        path: '/api/v1/event/update',
        method: 'POST'
    },

    getGameDetails: {
        path: '/api/v1/game/get-game',
    },


    getCategory: {
        path: '/api/v1/questions/get-category',
        method: 'GET'
    },

    getUserInfo: {
        path: '/api/v1/invite/user-info',
        method: 'GET'
    },

    getUserGameDetails: {
        path: '/api/v1/game/get-user-game-details',
    },
    getEventDetails: {
        path: '/api/v1/game/get-event-details',
    },
    getGameMembers: {
        path: '/api/v1/game/get-game-members',
    },
    getEventInvites: {
        path: '/api/v1/game/get-event-invites',
    },
    getUserPreviousGameDetails: {
        path: '/api/v1/game/get-user-previous-game-details',
    },
    endGame: {
        path: '/api/v1/event/end-event',
        method: 'POST'
    },
 

    //Auth
    postRegister: {
        path: '/api/v1/auth/register',
        method: 'POST'
    },
    postLogin: {
        path: '/api/v1/auth/login',
        method: 'POST'
    },
    registerOAuth: {
        path: '/api/v1/auth/register-oauth',
        method: 'POST'
    },
    getUser: {
        path: '/api/v1/auth/user',
    },
    postForgotPassword: {
        path: '/api/v1/auth/forgot-password',
        method: 'POST'
    },
    postResetPassword: {
        path: '/api/v1/auth/reset-password',
        method: 'POST'
    },
    sendVerificationCode: {
        path: '/api/v1/auth/send-verification-code',
        method: 'POST'
    },
    emailVerification: {
        path: '/api/v1/auth/email-verification',
        method: 'POST'
    },
    orgUserDetails: {
        path: '/api/v1/auth/org-user-details',
        method: 'GET'
    },
    orgCreateAccount: {
        path: '/api/v1/auth/org-create-account',
        method: 'POST'
    },

    updateProfile: {
        path: '/api/v1/auth/update-profile',
        method: 'POST'
    },
    changePassword: {
        path: '/api/v1/auth/change-password',
        method: 'POST'
    },
    changePswdUserInfo: {
        path: '/api/v1/auth/change-pswd-user-info'
    },
    sendLead: {
        path: '/api/v1/auth/send-lead',
        method: 'POST'
    },
    getAllUsersList: {
        path: '/api/v1/auth/list-all-users',
        method: 'GET'
    },
    addUsersInfoToDB: {
        path: '/api/v1/auth/add-users-info-to-db',
        method: 'POST'
    },
    deleteUserInfo: {
        path: '/api/v1/auth/delete-user-info',
        method: 'POST'
    },
    getAllCalenderEventsList: {
        path: '/api/v1/calender-event/get-calender-event',
        method: 'GET'
    },
    addCalenderEventsToDB: {
        path: '/api/v1/calender-event/add-calender-events-to-db',
        method: 'POST'
    },
    restoreUserStatus: {
        path: '/api/v1/auth/restore-user-info',
        method: 'POST'
    }

}
