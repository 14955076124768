
export const feedReducer = (state = {
    feedList: null,
    isCreatePoll: false,
    isActivePoll:false
}, action) => {
    let nextState = state;

    switch (action.type) {
        case 'feed-append':
            if (Array.isArray(action.data)) {
                nextState = Object.assign({}, state, {
                    feedList: state.feedList.concat(action.data),
                });
            } else {
                if (state.feedList) {
                    nextState = Object.assign({}, state, {
                        feedList: state.feedList.filter(item => item.id != action.data.id).concat([action.data]).sort((a, b) => {
                            if ( a.updatedAt > b.updatedAt ) {
                                return -1;
                            }
                            if ( a.updatedAt < b.updatedAt ) {
                                return 1;
                            }
                            return 0;
                        }),
                    });
                } else {
                    nextState = Object.assign({}, state, {
                        feedList: [action.data]
                    });
                }
            }
            break;

        case 'feed-set':
            nextState = Object.assign({}, state, {
                feedList: action.data,
            });
            break;

        case 'feed-create-poll':
            nextState = Object.assign({}, state, {
                isCreatePoll: !state.isCreatePoll,
            });
            break;    
         
        case 'feed-active-poll':
            nextState = Object.assign({}, state, {
                isActivePoll: action.data,
            });
            break;      

        default:
            break;
        
            

    }
    return nextState;
}


export const appendFeedAction = (data) => {
    return {
        type: 'feed-append',
        data
    }
}

export const setFeedAction = (data) => {
    return {
        type: 'feed-set',
        data
    }
}

export const setCreatePoll = () => {
    return {
      type: 'feed-create-poll',
    }
}

export const setIsActivePoll = (data) => {
    return {
      type: 'feed-active-poll',
      data
    }
}