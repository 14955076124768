export const eventReducer = (state = {
    eventDetail: null,
    funUniverse: null
}, action) => {
    let nextState = state;
    switch (action.type) {
        case 'set-event-data':
            if (action.data) {
                nextState = Object.assign({}, state, {
                    eventDetail: action.data
                });
            }
            break;

        case 'set-fun-universe-data':
            if (action.data) {
                nextState = Object.assign({}, state, {
                    funUniverse: action.data
                });
            }
            break;

        default:
            break;
    }
    return nextState;
}

export const setEventData = (data) => {
    return {
        type: 'set-event-data',
        data: data
    }
}

export const setFunUniverseEvent = (data) => {
    return {
        type: 'set-fun-universe-data',
        data: data
    }
}