 
import queryString from "querystring";
import axios from "axios";
import endpoints from "./endpoints";

export const actions = {
    FETCH_REQUEST: "FETCH_REQUEST",
    FETCH_REQUEST_SUCCESS: "FETCH_REQUEST_SUCCESS",
    FETCH_REQUEST_ERROR: "FETCH_REQUEST_ERROR",
    SET_DATA: "SET_DATA",
    EMIT: 'EMIT'
};



export const setStoreData = (key, value) => {
    return {
        type: actions.SET_DATA,
        key,
        value,
    };
};

export const fetchDataRequest = (key) => {
    return {
        type: actions.FETCH_REQUEST,
        key,
    };
};

export const fetchDataRequestError = (key, error) => {
    return {
        type: actions.FETCH_REQUEST_ERROR,
        key,
        error,
    };
};

export const fetchResponseSuccess = (key, data) => {
    return {
        type: actions.FETCH_REQUEST_SUCCESS,
        key,
        data,
    };
};



export const dispatchRequest = (key, path = [], params = {}) => {
    return (dispatch) => {
        const request = Object.assign({}, endpoints[key]);
        if (path.length > 0) {
            request.path += "/" + path.join("/");
        }

        if (request.method === undefined || request.method === "GET") {
            request.method = "GET";
            const qs = queryString.stringify(params);
            if (qs !== "") {
                request.path += `?${qs}`;
            }
        }

        dispatch(fetchDataRequest(key));

        return axios[request.method.toLowerCase()](request.path, params)
            .then((response) => {
                dispatch(fetchResponseSuccess(key, response.data));
                return response;
            })
            .catch((error, response) => {
                if (error?.response?.status == 401) {
                    localStorage.removeItem('accessToken');
                }
                dispatch(fetchDataRequestError(key, error));
                return error.response;
            });
    };
};


export const getUser = () => {
    return dispatchRequest("getUser", [], {});
};