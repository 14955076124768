import React, { lazy, Suspense } from 'react';
import { useRoutes, useRedirect, getWorkingPath } from 'hookrouter';
import DashboardNew from '../components/Event/DashboardNew';
import { useSelector } from 'react-redux';
import { userDataMapper } from '../Redux/mappers';
import { navigateX } from '../helpers';
import PreLoader from '../components/common/PreLoader';
import { Helmet } from "react-helmet";

const HomeCorporate = lazy(() => import('../components/Home/HomeCorporate'));
const HomeFamily = lazy(() => import('../components/Event/HomeFamily'));
const HeaderLand = lazy(() => import('../components/common/HeaderLand'));
const JoinGame = lazy(() => import('../components/Event/JoinGame'));
const EmployerSignin = lazy(() => import('../components/Auth/EmployerSignin'));
const EmployerVerify = lazy(() => import('../components/Auth/EmployerVerify'));
const EmployerRegister = lazy(() => import('../components/Auth/EmployerRegister'));
// const DashboardNew = lazy(() => import('../components/Event/DashboardNew'));
const CreateGameNew = lazy(() => import('../components/Event/CreateGameNew'));
const ForgotPassword = lazy(() => import('../components/Auth/ForgotPassword'));
const TEContainer = lazy(() => import('../components/Event/TEContainer'));
const ResetPassword = lazy(() => import('../components/Auth/ResetPassword'));
const GameLayoutNew = lazy(() => import('../components/Event/GameLayoutNew'));
const EventHeader = lazy(() => import('../components/Event/Header'));
const ChatBox = lazy(() => import('../components/Chat/ChatBox'));
const HeaderGame = lazy(() => import('../components/common/HeaderGame'));
const AppHeader = lazy(() => import('../components/common/AppHeader'));
const AdminAcceptInvites = lazy(() => import('../components/Settings/AdminAcceptInvites'));
const SelectRounds = lazy(() => import('../components/Event/SelectRounds'));
const Main = lazy(() => import('../components/Settings/Main'));
const Team = lazy(() => import('../components/Trivia/Team'));
const Question = lazy(() => import('../components/Trivia/Question'));
const AcceptInvites = lazy(() => import('../components/Event/AcceptInvite'));
const RoundCompleted = lazy(() => import('../components/Trivia/RoundCompleted'));
const NotFound = lazy(() => import('../components/common/NotFound'));
const Footer = lazy(() => import('../components/common/Footer'));
const TermsConditionsPage = lazy(() => import('../components/Home/TermsConditionsPage'));
const PrivacyPolicy = lazy(() => import('../components/Home/PrivacyPolicy'));

const routes = {
      "/": () => <HomeCorporate />,
      "/home-fam": () => <HomeFamily />,
      "/home-corp": () => <HomeCorporate />,
      "/join-game/:eventId": (eventId) => <JoinGame eventId={eventId} />,
      "/team/:eventId": ({ eventId }) => <Team eventId={eventId} />,
      "/question/:eventId/:roundId": ({ eventId, roundId }) =>
            <Question eventId={eventId} roundId={roundId} />,
      "/round-completed/:eventId": ({ eventId }) =>
            <RoundCompleted eventId={eventId} />,
      "/accept-invite/:eventId/:token": ({ token, eventId }) =>
            <AcceptInvites token={token} eventId={eventId} />,
      "/dashboard/:filter?": ({ filter }) => <DashboardNew filter={filter} />,
      "/dashboard": () => <DashboardNew filter='active' />,
      "/reset-password/:token": ({ token }) =>
            <ResetPassword token={token} />,
      "/event/:eventId": ({ 
            eventId
      }) => <GameLayoutNew 
                  eventId={eventId}
                  tabName='home'
                  param1=''
                  param2='' />,
      "/event/:eventId/:tabName?": ({
            eventId,
            tabName,
      }) => <GameLayoutNew
                  eventId={eventId}
                  tabName={tabName}
                  param1=''
                  param2='' />,
      "/event/:eventId/:tabName/:firstId?": ({
            eventId,
            tabName,
            firstId,
      }) => <GameLayoutNew
                  eventId={eventId}
                  tabName={tabName}
                  param1={firstId}
                  param2='' />,
      "/event/:eventId/:tabName/:firstId/:secondId?": ({
            eventId,
            tabName,
            firstId,
            secondId
      }) => <GameLayoutNew
                  eventId={eventId}
                  tabName={tabName}
                  param1={firstId}
                  param2={secondId} />,

      "/org-verify-account": () => <EmployerVerify />,
      "/org-signin": () => <EmployerSignin />,
      "/org-create-account": () => <EmployerRegister />,
      "/forgot-password": () => <ForgotPassword />,
      "/settings/:menuOption": ({menuOption}) => <Main menuOption={menuOption} />,
      "/select-rounds": () => <SelectRounds />,
      "/terms-conditions": () => <TermsConditionsPage />,
      "/privacy-policy": () => <PrivacyPolicy />,
      "/select-rounds/:eventId?": ({ eventId }) => <CreateGameNew eventId={eventId} />,
      "/admin-accept-invite/:token": ({ token }) => <AdminAcceptInvites token={token} />,
      "/*": () => <NotFound />,
};

const AppRoutes = (props) => {
      useRedirect('/', '/home-corp');
      const pages = useRoutes(routes);
      let { eventId, tabName } = pages.props;
      const path = getWorkingPath();
      const currentUser = useSelector(st => userDataMapper(st));

      const { expand } = useSelector(state => state?.chat);
      let appContent = (
            <div className="w3-row">
                  <div className="w3-col s12">
                        <div className="main-div">
                             {pages}
                        </div>
                  </div>
            </div>
      );

      if (eventId) {
            appContent = pages;
      }
      let page = path.split("/");
      let content = null;
      //reroutes to dashboard if user is logged in and tries to signin or verify
      if (currentUser && (path == "/org-signin" || path == "/org-verify-account")) {
            navigateX('/dashboard')
      }
      if (path === "/home-fam" || path === "/home-corp") {
            content = <div className="app-bg-land">
                  <div className="app-content">
                        <HeaderLand theme={'color'} />
                        {appContent}
                        <Footer theme={'lite'} />
                  </div>
            </div>
      } else if (path.indexOf("/dashboard") == 0) {
            content = (
                  <>
                        <Helmet>
                              <title>Team Engage | Dashboard</title>
                        </Helmet>
                        <div className="bg-theme">
                              <div className="app-content">
                                    {<AppHeader isEvent={false} />}
                                    <div className='w3-row'>
                                          <div id="te-chat-box" className={`${(expand) ? 'w3-col m3 w3-right chat-box-expand' : 'w3-col w3-right chat-box-collapse'}`}>
                                                {Boolean(currentUser) && <ChatBox
                                                      path={path}
                                                      user={currentUser}
                                                />}
                                          </div>
                                          <div className={`${(expand) ? 'w3-col m9 chat-expand-content' : 'w3-rest  chat-collapse-content'}`}>
                                                {appContent}
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </>
            )
      } else if (page[1] === 'event') {
            content = (
                  <>
                        <Helmet>
                              <title>Team Engage | Event</title>
                        </Helmet>
                        <div className="app-bg-image">
                              <div className="app-content">
                                    {tabName && (tabName !== 'trivia' && tabName !== 'play') && (
                                          <AppHeader isEvent={true} {...pages.props} />
                                    )}
                                    <div className='w3-row'>
                                          <div id="te-chat-box"
                                                className={`${(expand) ? 'w3-col m3 w3-right chat-box-expand' : 'w3-col w3-right chat-box-collapse'} ${(tabName && (tabName == 'trivia' || tabName == 'play')) ? 'te-no-header' : ''} ${tabName == 'play' ? 'hide-widget-play': ''}`}
                                          >
                                                {Boolean(currentUser) && <ChatBox
                                                      path={path}
                                                      user={currentUser}
                                                />}
                                          </div>
                                          <div className={`${(expand) ? 'w3-col m9 chat-expand-content' : 'w3-rest chat-collapse-content'}`}>
                                                <TEContainer {...pages.props}>
                                                      {appContent}
                                                </TEContainer>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </>
            )
      } else if (page[1] === 'fungames') {
            content = (
                  <>
                        <Helmet>
                              <title>Team Engage | Fun Games</title>
                        </Helmet>
                        <div className="app-bg-image">
                              <div className="app-content">
                                    {tabName && (tabName !== 'trivia' && tabName !== 'play') && (
                                          <AppHeader isEvent={true} {...pages.props} />
                                    )}
                                    <div className='w3-row'>
                                          <div id="te-chat-box" className={`${(expand) ? 'w3-col m3 w3-right' : 'w3-col w3-right'}`} style={(!expand) ? { width: '80px' } : {}}>
                                                {Boolean(currentUser) && <ChatBox
                                                      path={path}
                                                      user={currentUser}
                                                />}
                                          </div>
                                          <div className={`${(expand) ? 'w3-col m9' : 'w3-rest'}`} style={(!expand) ? { paddingRight: '80px' } : {}}>
                                                <TEContainer {...pages.props}>
                                                      {appContent}
                                                </TEContainer>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </>
            )
      } else if (page[1] == 'select-rounds') {
            content = <div className="app-bg-image">
                  <div className="app-content">
                        <AppHeader isEvent={false} />
                        <div className='w3-row'>
                              <div className={`w3-col m12`}>
                                    {appContent}
                              </div>
                        </div>
                  </div>
            </div>
      } else {
            content = <div className="app-bg-image" style={{background:'white'}}>
                  <div className="app-content">
                        <HeaderLand theme={'lite'} />
                        <div className='w3-row'>
                              <div className={`w3-col m12`}>
                                    {appContent}
                              </div>
                        </div>
                        <Footer theme={'lite'} />
                  </div>
            </div>
      }
      return <div id="team-engage-app" className="team-engage-app w3-row theme-dark">
            <Suspense fallback={<div className="w3-padding-64">
                  <div className='preloader-container'>
                        <PreLoader />
                  </div>
            </div>}>
                  {content}
            </Suspense>
      </div>
}

export default AppRoutes;