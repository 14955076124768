import { navigate } from 'hookrouter';
import { eventStatusObj } from '../components/Constants/constants';
import axios from 'axios';

export const makeId = (length = 8) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(random() * charactersLength));
    }
    return result;
}

export const random = () => {
    return Math.random();
}

export const showMoreInfo = (i) => {
    if (document.getElementById(i) && document.getElementById(i).style.display == 'none') {
        document.getElementById(i).style.display = 'block';
        return
    }
    if (document.getElementById(i)) document.getElementById(i).style.display = 'none';
}

export const useNavigateX = (path) => {
    return (path) => {
        return navigate(path)
    }
}

export const navigateX = (path) => {
    return navigate(path);
}

export const log = (...data) => {
    console.log(...data)
}

export const trimFn = (text) => {
    return text.toLowerCase()?.trim();
}

export const adminOrManagerUserCheck = (currentUser, event) => {
    let isAdmin = false;
    if (currentUser?.user_roles && event) {
        let idx = currentUser.user_roles.findIndex(i => i.groupId == event.groupId);
        if (idx != -1) {
            if ((currentUser.user_roles[idx].roleId == 'admin') || (currentUser.user_roles[idx].roleId == 'manager')) {
                isAdmin = true;
            }
        }
    }
    return isAdmin;
}

export const isEventCompleteOrEnded = status => (status === eventStatusObj.ended) || (status === eventStatusObj.completed);

export const mapGameProgress = (data) => {
    data.event.eventGames.forEach(item => {
        let totalQuestionCount = item.maxNoOfQuestions ? item.maxNoOfQuestions : item.noOfQuestions;
        const qList = data.teamQuestions.filter(q => q.roundId == item.id);
        const answeredCount = qList.filter(q => q.answerStatus === 'answer_submitted').length;
        const unAnsweredCount = qList.filter(q => (q.attemptsLeft == 3 && q.answerStatus === 'skip')).length;
        const wrongAnswerCount = qList.filter(q => (q.attemptsLeft < 3 && q.answerStatus != 'answer_submitted')).length;
        const pendingCount = totalQuestionCount - answeredCount - unAnsweredCount - wrongAnswerCount
        item.data = {
            progress: Math.floor((answeredCount / totalQuestionCount) * 100),
            answeredCount,
            unAnsweredCount,
            wrongAnswerCount,
            score: answeredCount * 10,
            rightAnswerProgress: Math.floor((answeredCount / totalQuestionCount) * 100),
            unAnsweredCountProgress: Math.floor((unAnsweredCount / totalQuestionCount) * 100),
            wrongAnswerCountProgress: Math.floor((wrongAnswerCount / totalQuestionCount) * 100),
            gameprogress: 100 - Math.floor((pendingCount / totalQuestionCount) * 100),
        };
	});
    return data;
}

export const redirectToFunUniverse = () => {
    axios.get(`/api/v1/event/get-fun-universe`)
    .then(resp => {
        if (!resp.data.success) {
            document.location.href = '/dashboard';
        } else {
            document.location.href = `/event/${resp.data.data.id}/feed`;
        }
    });
}