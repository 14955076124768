import axios from "axios"
import { socketIO } from "./channel";

export const getRoomListAction = (eventId) => dispatch => {
    return axios.get(`/api/v1/event/room-list/${eventId}`)
        .then(resp => {
            dispatch({
                type: 'chat-room-list',
                data: resp.data
            });
        });
}

export const appendMessageAction = (data) => {
    return {
        type: 'chat-append-message',
        data
    }
}

export const expandChatAction = (data) => {
    return {
        type: 'chat-room-expand',
        data
    }
}

export const refetchMessageAction = () => (dispatch, getState) => {
    const { chat: { selectedRoom } } = getState();
    return getRoomMessagesAction(selectedRoom);
}

export const getMembersAction = () => (dispatch, getState) => {
    const { chat: {
        selectedRoom: room
    } } = getState();
    return axios.get(`/api/v1/chat/room-members/${room.eventId}/${room.id}`).then(resp => {
        dispatch({
            type: 'chat-member-update',
            data: resp.data
        });
    })
}

export const selectRoomAction = (roomId) => {
    return {
        type: 'chat-change-room',
        data: roomId
    }
}

export const setMediaItem = (data) => {
    return {
        type: 'set-media-item',
        data: data
    }
}

export const getRoomMessagesAction = (room) => dispatch => {
    return axios.get(`/api/v1/chat/get-messages/${room.eventId}/${room.id}`).then(resp => {
        dispatch({
            type: 'chat-messages',
            roomId: room.id,
            data: resp.data.data
        });
    });
}

export const setChatEventAction = (eventId) => {
    return {
        type: 'chat-event',
        data: eventId
    }
}

export const muteStreams = (muteStatus) => {
    return {
        type: 'chat-mute-status',
        data: muteStatus
    }
}

export const setOnlineUsers = (data) => {
    return {
        type: 'set-online-users',
        data: data
    }
}

export const chatReducer = (state = {
    roomList: [],
    selectedRoom: null,
    messages: [],
    participants: [],
    expand: false,
    eventId: null,
    unreadCount: 0,
    muteStatus: {
        muteAll: false
    },
    onlineUsers: [],
    mediaItem: null
}, action) => {
    let nextState = state;

    if (action.type == 'chat-room-list') {
        const roomList = action.data.map(item => Object.assign({}, item.room, {
            receivedCount: item.receivedCount,
            unreadCount: parseInt(`${item.room.messageCount}`) - parseInt(`${item.receivedCount}`)
        }));

        const unreadCount = roomList.reduce((agg, item) => {
            return agg + item.unreadCount;
        }, 0);

        let selectedRoom = state.selectedRoom;

        if (window.location.pathname.indexOf('/celebrate') == -1) {
            if (!selectedRoom) {
                selectedRoom = action.data[0]?.room;
            } else if (selectedRoom.eventId !== state.eventId) {
                selectedRoom = action.data[0]?.room;
            }
        }

        nextState = Object.assign({}, state, {
            roomList,
            unreadCount,
            selectedRoom
        });
    } else if (action.type === 'chat-stat-message') {
        let unreadCount = 0;
        if (action.data.eventId !== state.eventId) {
            //parallel events
            return nextState;
        }
        state.roomList.forEach(item => {
            if (action.data.roomId == state.selectedRoom?.id && state.expand) {
                item.unreadCount = 0;
            } else if (item.id == action.data.roomId) {
                item.unreadCount = parseInt(`${action.data.messageCount}`) - parseInt(`${action.data.receivedCount}`);
            }
            unreadCount += item.unreadCount
        })
        nextState = Object.assign({}, state, {
            roomList: [].concat(state.roomList),
            unreadCount,
            selectedRoom: state.selectedRoom ? state.selectedRoom : action.data[0]?.room
        });
    } else if (action.type === 'chat-room-expand') {
        const unreadCount = state.roomList.reduce((agg, item) => {
            if (!action.data) {
                return agg + item.unreadCount;
            }
            if (item && state.selectedRoom && item.id != state.selectedRoom.id) {
                agg += item.unreadCount;
            }
            return agg;
        }, 0);
        nextState = Object.assign({}, state, {
            expand: action.data,
            unreadCount
        });
    }

    switch (action.type) {
        case 'chat-change-room':
            nextState = Object.assign({}, state, {
                selectedRoom: state.roomList.find(item => item.id === action.data)
            });
            break;
        case 'chat-event':
            nextState = Object.assign({}, state, {
                eventId: action.data
            });
            break;
        case 'chat-append-message':
            if (state.messages) {
                nextState = Object.assign({}, state, {
                    messages: state.messages.filter(item => item.id != action.data.id).concat([action.data]),
                });
            } else {
                nextState = Object.assign({}, state, {
                    messages: state.messages,
                });
            }
            break;

        case 'chat-messages':
            nextState = Object.assign({}, state, {
                messages: action.data,
            });
            break;
        case 'chat-member-update':
            nextState = Object.assign({}, state, {
                participants: action.data
            });
            break;
        case 'chat-mute-status':
            nextState = Object.assign({}, state, {
                muteStatus: action.data
            });
            break;
        case 'set-online-users':
            nextState = Object.assign({}, state, {
                onlineUsers: action.data
            });
            break;
        case 'chat-message-count':
            break;
        case 'set-media-item':
            nextState = Object.assign({}, state, {
                mediaItem: action.data
            });
            break;
        default:
            break;

    }
    return nextState;
}