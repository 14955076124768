import React, { Component } from 'react';

export class LoadingIcon extends Component {
	render() {
		return (
			<div className="w3-row m12 h7t-loader ">
				<div className="spinner">
					<div className="bounce1" />
					<div className="bounce2" />
					<div className="bounce3" />
				</div>
			</div>
		);
	}
}