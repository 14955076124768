import { wsHost } from '../components/Constants/constants';
const {io} = require('socket.io-client');


let extraHeaders = {};

const token = localStorage.getItem("accessToken");
if (token) {
    extraHeaders["Authorization"] = token;
}

export const socketIO = io(wsHost, {
    extraHeaders
});

export const sendSocketMsg = (message, data) => {
    data.token = token;
    socketIO.emit(message,data);
}

export const leaveRoomAction = (room) => dispatch => {
    socketIO.emit('leaveRoom', {
        room
    });

    dispatch({
        type: 'channel-leave-room',
        data: room
    })
}

export const joinRoomAction = (room) => (dispatch, getState) => {
    socketIO.emit('joinRoom', {
        room
    });
    setInterval(() => {
        const { channels } = getState();
        // heartbeat logic
    }, 30000);

    dispatch({
        type: 'channel-join-room',
        data: room
    })
}

export const reconnectAction = () => (dispatch, getState) => {
    const { channels } = getState();
    for (var room of channels) {
        dispatch(joinRoomAction(room));
    }
}

// const connectedRooms = localStorage.getItem('connectedRooms');
export const channelReducer = (state = [], action) => {
    let nextState = state;
    if (action.type == 'channel-join-room') {
        if (state.indexOf(action.data) === -1) {
            nextState = state.concat(action.data);
        }
    } else if (action.type == 'channel-leave-room') {
        nextState = state.filter(item => item !== action.data);
    }
    return nextState;
}