import React, { useEffect, useState } from 'react'
import Axios from 'axios';
import PreLoader from '../common/PreLoader';
import { navigateX } from '../../helpers';
import { Helmet } from "react-helmet";

const FungamesHome = ({ eventId, funUniverse, limit = 200 }) => {
    const [gameList, setGameList] = useState(null);
    useEffect(() => {
        Axios.get(`/api/v1/games/get-fun-games/${eventId}`).then(resp => {
            setGameList(resp.data);
        });
    }, [])

    if (!gameList) {
        return <div className="w3-padding-64">
            <div className='preloader-container'>
                <PreLoader />
            </div>
        </div>
    } else {
        const duels = [];
        const spGames = [];
        gameList && gameList.forEach((item, idx) => {
            if (item?.data?.maxPlayers == 2 && duels.length < limit) {
                duels.push(<div className="te-card-container" key={item.id}>
                    <FunGameCard
                        textColor={item.data.theme[0].textColor}
                        backgroundColor={item.data.theme[0].backgroundColor}
                        backgroundImage={item.data.theme[0].backgroundImage}
                        thumbnailImage={item.data.theme[0].thumbnailImage.sourceUrl}
                        name={item.name}
                        description={item.description}
                        eventId={eventId}
                        gameSlug={item.gameSlug}
                        leaders={item.topThree}
                    />
                </div>)
            } else if (item?.data?.maxPlayers == 1 && spGames.length < limit) {
                spGames.push(<div className="te-card-container" key={item.id}>
                    <FunGameCard
                        textColor={item.data.theme[0].textColor}
                        backgroundColor={item.data.theme[0].backgroundColor}
                        backgroundImage={item.data.theme[0].backgroundImage}
                        thumbnailImage={item.data.theme[0].thumbnailImage.sourceUrl}
                        name={item.name}
                        description={item.description}
                        eventId={eventId}
                        gameSlug={item.gameSlug}
                        leaders={item.topThree}
                    />
                </div>)

            }
        });
        return <div className="w3-panel w3-round-xlarge w3-margin-bottom w3-text-white te-selected-games">
            <div className='w3-margin-left headline-text-medium game-type-title'>Multi Player Games</div>
            <div className='te-flex te-cards-container'>
                {duels}
                {!duels.length && <div className='w3-padding w3-padding-24'>No multi player games.</div>}
            </div>
            <div className='margin-top-30 w3-margin-left headline-text-medium game-type-title'>Single Player Games</div>
            <div className='te-flex te-cards-container'>
                {spGames}
                {!spGames.length && <div className='w3-padding w3-padding-24'>No single player games.</div>}
            </div>
        </div>
    }

}

function FunGameCard(props) {
    const { textColor, backgroundColor, backgroundImage, thumbnailImage,
        name, description, eventId, gameSlug, leaders } = props;

    const showMoreInfo = (Id) => {
        if (!document.getElementById(Id)) return
        if (document.getElementById(Id).style.display == 'none') {
            document.getElementById(Id).style.display = 'block';
            return
        }
        document.getElementById(Id).style.display = 'none';
    }

    return (
        <>
            <Helmet>
                <title>Team Engage | Fun Games</title>
            </Helmet>
            <div className={`te-card-fungame`} key={gameSlug}>
                <div className='thumbnail'
                    onMouseEnter={() => showMoreInfo(`description-${gameSlug}`)}
                    onMouseLeave={() => showMoreInfo(`description-${gameSlug}`)}
                >
                    <img
                        src={thumbnailImage}
                        width="100%"
                        height="auto"
                        alt="Image overlay"
                    />
                    <div
                        className="te-text-work-12 game-description-overlay"
                        id={`description-${gameSlug}`}
                        style={{ display: 'none', overflow: 'hidden' }}
                    >
                        <p className='w3-margin-left w3-margin-right game-description'>{description}</p>
                    </div>
                </div>
                <div className='w3-padding'>
                    <div
                        className="body-text-large te-text-bolder"
                        style={{ textAlign: 'left' }}
                    >
                        {name}
                    </div>
                    <div className='w3-center te-card-content-block'>
                        <div className='top-teams'>
                            {
                                leaders.length > 0 ?
                                    <>{leaders.map((el, i) =>
                                        <div className='top-teams-team'
                                            onMouseEnter={() => showMoreInfo(`player-${gameSlug}-${i}`)}
                                            onMouseLeave={() => showMoreInfo(`player-${gameSlug}-${i}`)}
                                            key={i}
                                        >
                                            <div
                                                id={`player-${gameSlug}-${i}`}
                                                className="w3-panel w3-padding te-text-work-14 popup-name w3-border ellipsis"
                                                style={{ display: 'none', maxWidth: '180px' }}
                                            >{el.users_name}
                                            </div>
                                            {
                                                el.users_profilePic ?
                                                    <img className='top-teams-profile-img profile-pic' src={el.users_profilePic} /> :
                                                    <div className='top-teams-profile-img'>{el.users_name.charAt(0).toUpperCase()}</div>
                                            }
                                            {/* <div className='w3-center w3-tiny w3-opacity'>{el.name}</div> */}
                                            <div className='w3-center'>{Math.round(parseInt(el.game_players_points))}&nbsp;
                                                <span className='w3-center' style={{ lineHeight: '5px' }}>points</span>
                                            </div>
                                        </div>)
                                    }{
                                            leaders.length > 0 &&
                                            [...Array(3 - leaders.length)].map(
                                                (e, i) =>
                                                    <div className='top-teams-team' key={i}>
                                                        <div className='top-teams-profile-img no-player'
                                                            onMouseEnter={() => showMoreInfo(`no-player-${gameSlug}-${i}`)}
                                                            onMouseLeave={() => showMoreInfo(`no-player-${gameSlug}-${i}`)}
                                                        ></div>
                                                        <div
                                                            id={`no-player-${gameSlug}-${i}`}
                                                            className="w3-panel w3-padding sub-text-large te-pop-up-prize w3-border"
                                                            style={{ display: 'none' }}
                                                        >
                                                            <b>This could be you!</b> <br />Play and win the match to see yourself here.
                                                        </div>
                                                        <div className='w3-center'></div>
                                                    </div>
                                            )
                                        }</> :
                                    <div className='sub-text-large'
                                    >
                                        <i>Winners showup here, You got what it takes ?</i>
                                    </div>
                            }

                        </div>
                    </div>
                    <div className="w3-center w3-display-container body-text-small">
                        <button
                            className="te-button-primary w3-button w3-round-xxlarge"
                            onClick={e => navigateX(`/event/${eventId}/lobby/${gameSlug}`)}
                        ><b>Play Game</b>
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}


export default FungamesHome;