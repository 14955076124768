import React, { useEffect } from 'react';
import './scss/app.scss';
import './scss/fungames.scss';
import AppRoutes from './Router/AppRoutes';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from './Redux/actions';
import { userDataMapper } from './Redux/mappers';
import { LoadingIcon } from './components/common/LoadingIcon';
import PreLoader from './components/common/PreLoader';
import { getWorkingPath } from "hookrouter"; 
import { navigateX } from './helpers';

const App = () => {

    const dispatch = useDispatch();
    const path = getWorkingPath();
    let page = path.split("/");
    const user = useSelector(st => userDataMapper(st));
    useEffect(() => {
        dispatch(getUser()).then(resp => {
            if(!resp.data.success && (page[1]=="event" || page[1]=="settings")){
                navigateX('/')
            }
            console.error('Auth ', resp);
        }).catch(error => {
            console.error('Auth Error',error);
        });
    },[1])

    if (user === null) {
       return (
            <div className="w3-padding-64">
                {/* <LoadingIcon/> */}
                <div className='preloader-container'>
                    <PreLoader />
                </div>
            </div>
       )
    }

    return (
        <AppRoutes />
    );
};

export default App;
