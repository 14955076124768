import React from 'react';
import Lottie from 'react-lottie';
import * as loading from '../../images/loading.json'


const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: loading.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };


export default function PreLoader({height,width}) {
    return (
        <div style={{ minHeight: '100vH' }}>
          <Lottie options={defaultOptions} height={height} width={width}/>
        </div>
    )
}
